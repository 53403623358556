import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/free-solid-svg-icons/faCookieBite';

import { useTranslation } from 'react-i18next';
import PrivacyPolicyContent from '../legal/PrivacyPolicyContent';
import { TopicV2 } from '@kazvabg/voterapp-model';
import MatomoTracker from '../util/matomo/MatomoTracker';
import { useParams } from 'react-router-dom';
import TypingTextV2 from './TypingTextV2';

import DisplayOnboardingVideo from '../pages/topic/DisplayOnboardingVideo';
import i18n from '../i18n';
import PrivacyPolicyContentENG from '../legal/PrivacyPolicyContentENG';

interface LinkButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    className?: string;
    ariaLabel?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ onClick, children }) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onClick();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (e.key === 'Enter' || e.key === ' ') {
            onClick();
        }
    };

    return (
        <a
            href='#'
            role="button"
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onAuxClick={(e) => e.preventDefault()}
        >
            {children}
        </a>
    );
};

const ButtonContainer = styled.div`
    margin: 0px 16px 16px;
    width: calc(100% - 32px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 104px;
`;

const ConsentContainer = styled.div`
    height: calc(100% - 136px);
    overflow: auto;
`;

export const StyledButtonV2 = styled.button`
    display: flex;
    position:relative;
    height: 48px;
    width: 100%;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 999px;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: #FFF;
    box-shadow: 0px 2.839px 8.518px 0px rgba(0, 0, 0, 0.10);
    font-weight: 600;
    cursor: pointer;
    color: black;
`;

const StyledContentPanelV2 = styled.div`
    h1 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 12px;
    }

    p {
        margin-bottom: 12px;
        font-size: 0.9rem;
    }

     @media (max-width: 480px) {
        h1 { font-size: 1rem; }
        p { font-size: 0.9rem; }
    }

    @media (max-width: 360px) {
        h1 { font-size: 0.9rem; }
        p { font-size: 0.7rem; }
    }

    @media (max-width: 320px) {
        h1 { font-size: 0.9rem; }
        p { font-size: 0.6rem; }
    }

    @media (max-width: 280px) {
        h1 { font-size: 0.8rem; }
        p { font-size: 0.5rem; }
    }

    @media (max-height: 700px) {
        h1 { font-size: 0.9rem; }
        p { font-size: 0.6rem; }
    }

    @media (max-height: 480px) {
        h1 { font-size: 0.8rem; }
        p { font-size: 0.5rem; }
    }

    @media (max-height: 320px) {
        h1 { font-size: 0.7rem; }
        p { font-size: 0.4rem; }
    }
`;

const PolicyScreenStyle = styled.div`
    padding: 20px;

    h1 {
        margin: 20px 0;
        font-size: 24px;
    }

    h2 {
        margin: 20px 0;
        font-size: 17px;//24px;
    }

    h3 {
        margin: 20px 0;
        font-size: 17px;
    }

    h4 {
        margin: 20px 0;
        font-size: 15px;
    }

    p, li {
        font-size: 15px;
        color: black;
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.7;
        font-weight: normal;
    }

    hr {
        margin: 20px 0;
        border: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
    }
`;

interface ConsentScreenV2Props {
    topic: TopicV2 | null;
    onConsentGiven: () => Promise<void>;
}

interface ConsentScreenV2State {
    visible: boolean;
    policyOpen: boolean;
    cookiesDenied: boolean;
}

const ConsentScreenV2Component: React.FC<ConsentScreenV2Props> = (props) => {

    const { topicHash }: { topicHash: string } = useParams();

    const onboardingVideoUrl = 'https://cdn.kazva.bg/videos/onboarding/video.m3u8';

    const [state, setState] = useState<ConsentScreenV2State>({
        visible: false,
        policyOpen: false,
        cookiesDenied: false
    });

    const { t } = useTranslation();
    
    const clickPolicy = () => {

        MatomoTracker.trackEvent({
            category: 'cookie-consent',
            action: 'open-privacy-policy',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ ...state, policyOpen: true });
    };

    const allowCookies = () => {

        MatomoTracker.trackEvent({
            category: 'cookie-consent',
            action: 'allow-cookies',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ ...state, visible: false });

        setTimeout(() => {
            props.onConsentGiven();
        }, 650);
    };

    const denyCookies = () => {

        MatomoTracker.trackEvent({
            category: 'cookie-consent',
            action: 'deny-cookies',
            documentTitle: props.topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ ...state, cookiesDenied: true, policyOpen: false });
    };

    useEffect(() => {
        const animationFrame = requestAnimationFrame(() => {
            const timer = setTimeout(() => {
                setState({ ...state, visible: true });
            }, 50);
            return () => clearTimeout(timer);
        });

        return () => cancelAnimationFrame(animationFrame);
    }, []);

    // get current language
    const currentLanguage = i18n.language;

    return (
        <div style={{
            height: '100%',
            position: 'fixed',
            width: '100%',
            zIndex: 999,
            overflow: 'hidden',
        }}>
            <div style={{
                position:'absolute',
                width: '100%',
                height: 'calc(50% + 24px)',
                transform: 
                    !state.visible
                    ? 'translateY(200%)'
                    : state.cookiesDenied 
                        ? 'translateY(0%)'
                        : 'translateY(100%)',
                transition: 'transform 0.6s ease-in-out',
                borderRadius: '24px 24px 0px 0px',
                zIndex: "998",
                overflow: 'hidden',
                background: 'black',
            }}>
                {!state.cookiesDenied ? null :  
                    <DisplayOnboardingVideo
                        shouldPlay={true}
                        mediaUrl={onboardingVideoUrl}
                    />
                }
            </div>
            <div style={{
                height: state.policyOpen ? '100%' : '50%',
                //transition: 'height 0.25s ease-in-out',
                position: 'absolute',
                bottom: '0',
                borderRadius: '24px 24px 0px 0px',
                background:'#E6E6E6',
                zIndex: "999",
                transition: 'transform 0.6s ease-in-out, height 0.25s ease-in-out',
                transform: !state.visible ? 'translateY(100%)' : 'translateY(0%)',
            }}>
                <ConsentContainer>
                    {state.policyOpen
                        ? <PolicyScreenStyle>
                            {currentLanguage === 'bg' ? <PrivacyPolicyContent /> : <PrivacyPolicyContentENG />}
                        </PolicyScreenStyle>
                        : state.cookiesDenied
                            ? <div style={{ padding: '16px' }}>
                                <StyledContentPanelV2>
                                    <h1>
                                        <span style={{ marginRight: '8px' }}><FontAwesomeIcon icon={faCookieBite} /></span>
                                        {t('cookie_consent_denied_title')}
                                    </h1>
                                    <TypingTextV2 delay={1}>
                                        <p>{t('cookie_consent_denied_text1')}</p>
                                        <p>{t('cookie_consent_denied_text2')}</p>
                                        <p>
                                            {t('cookie_consent_according') + ' '} <LinkButton onClick={clickPolicy}>{t('menu_main_privacy_notice')}</LinkButton>
                                        </p>
                                    </TypingTextV2>
                                </StyledContentPanelV2>
                                <ButtonContainer>
                                    <StyledButtonV2 onClick={allowCookies}>
                                        ✅ {t('cookie_consent_allow_cookie')}
                                    </StyledButtonV2>
                                    <StyledButtonV2 onClick={denyCookies} style={{ marginTop: '8px' }}>
                                        ❌ {t('cookie_consent_deny_cookie')}
                                    </StyledButtonV2>
                                </ButtonContainer>
                            </div>
                            : <div style={{ padding: '20px' }}>
                                <StyledContentPanelV2>
                                    <h1>
                                        <span style={{ marginRight: '8px' }}><FontAwesomeIcon icon={faCookieBite} /></span>
                                        {t('cookie_consent_title')}
                                    </h1>
                                    <p>{t('cookie_consent_text1')}</p>
                                    <p>{t('cookie_consent_text2')}</p>
                                    <p>
                                        {t('cookie_consent_according')}
                                        {' '}
                                        <LinkButton onClick={clickPolicy}>
                                            {t('menu_main_privacy_notice')}
                                        </LinkButton>
                                    </p>
                                </StyledContentPanelV2>
                            </div>
                    }
                </ConsentContainer>
                <ButtonContainer>
                    <StyledButtonV2 onClick={allowCookies}>
                        ✅ {t('cookie_consent_allow_cookie')}
                    </StyledButtonV2>
                    <StyledButtonV2 onClick={denyCookies} style={{ marginTop: '8px' }}>
                        ❌ {t('cookie_consent_deny_cookie')}
                    </StyledButtonV2>
                </ButtonContainer>
            </div>
        </div>
    );
}

export default ConsentScreenV2Component;