import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "general_male": 'male',
                "general_female": 'female',
                "general_male_plural": 'men',
                "general_female_plural": 'women',
                "general_error_message": "Sorry, there was an error.",
                "general_rotate_device": "Rotate your device",

                "button_back": "Back",
                "button_confirm": 'Send',
                "button_continue": 'Continue',
                "button_skip": 'Skip',
                "label_you": "You",
                "label_please_choose": "Please choose",

                "image_upload_button_label": "Upload image",
                "image_upload_loading": "Uploading image",
                "image_upload_delete": "Delete image",

                "cookie_consent_title": "Allow cookie",
                "cookie_consent_text1": "The purpose of kazva.bg is to give power to your opinion!",
                "cookie_consent_text2": "We use only our own cookie, to ensure the security and weight of your opinions, settings, and history.",
                "cookie_consent_according": "Read our",
                "cookie_consent_allow_cookie": "Allow cookie",
                "cookie_consent_deny_cookie": "Deny cookie",
                "cookie_consent_denied_title": "Cookie denied",
                "cookie_consent_denied_text1": "kazva.bg cannot make your opinion heard without using our cookie, due to legal requirements and security considerations.",
                "cookie_consent_denied_text2": "To use kazva.bg, you must accept our cookie. It is made by us with the utmost care for your security and data.",
                
                'first_vote_title': 'Congratulations on your first vote!',
                'first_vote_text1': 'This message will not appear on your subsequent votes.',
                'first_vote_text2': 'After voting, you will not be able to go back, unless you restart the entire process, so please choose -',
                'first_vote_confirm': 'Confirm vote',
                'first_vote_try_again': 'Try again',

                "input_placeholder_name": 'Your name',
                "input_placeholder_gender": 'Your gender',
                "input_placeholder_birth_year": 'Your birth year',
                "input_placeholder_city": 'City',
                "input_placeholder_region": 'Region',
                "input_placeholder_country": 'Country',

                "home_message": "Hello, with us you see what Bulgaria says about:",

                "menu_main_title": "Menu",
                "menu_main_profile": "Profile",
                "menu_main_about_us": "About us",
                "menu_main_language": "Language",
                "menu_main_privacy_notice": "Privacy notice",

                "menu_profile_title": "Profile",
                "menu_profile_button_save": 'Save changes',
                "menu_profile_button_discard": 'Discard changes',
                "menu_profile_button_delete": 'Delete profile',
                "menu_profile_toast_success": "Profile saved successfully.",
                "menu_profile_ays_title": "Are you sure you want to delete your profile?",
                "menu_profile_ays_subtitle": "This action is irreversible!",
                "menu_profile_ays_button_yes": "Yes",
                "menu_profile_ays_button_no": "No",

                "menu_language_title": "Choose language",

                "slider_subtitle": "tell us by sliding the ball from 1 to 10",
                "slider_subtitle_vote_value": "You chose <b>{{ voteValue }} out of 10</b>",
                "slider_saying_to": "saying to",

                'qna_open_ended_disclaimer': 'By pressing this button you agree that your text will be made available to the organization operating this channel for feedback.',
            
                "end_screen_text": "You have answered all questions, thank you!",
                "end_screen_vote_again": "Give your opinion again",
                "end_screen_copy_link": "Copy link to the topic",
                "end_screen_copied_link" : "Copied!",
                "end_screen_share_topic" : "Share topic with friends",

                "results_majority_first_vote": "You are <b>the first</b> to answer this question!",
                "results_majority_strong_agreement": "Most people <b>agree</b> with you.",
                "results_majority_weak_agreement": "Most people <b>mostly agree</b> with you.",
                "results_majority_weak_disagreement": "Most people <b>mostly disagree</b> with you.",
                "results_majority_strong_disagreement": "Most people <b>disagree</b> with you.",

                "results_gender_strong_agreement": "Most <b>{{ genderStr }}</b> <b>agree</b> with you!",
                "results_gender_weak_agreement": "Most <b>{{ genderStr }}</b> <b>mostly agree</b> with you!",
                "results_gender_weak_disagreement": "Most <b>{{ genderStr }}</b> <b>mostly disagree</b> with you!",
                "results_gender_strong_disagreement": "Most <b>{{ genderStr }}</b> <b>disagree</b> with you!",
                "results_gender_first_vote_male": "You are <b>the first man</b> to answer this question!",
                "results_gender_first_vote_female": "You are <b>the first woman</b> to answer this question!",
                "results_gender_input_let_me_show_you": "Let me show you if people of your gender agree with you!",
                "results_gender_input_disclaimer": "Your gender will not be disclosed to anyone.",

                "results_age_strong_agreement": "Most people of your age <b>agree</b> with you.",
                "results_age_weak_agreement": "Most people of your age <b>mostly agree</b> with you.",
                "results_age_weak_disagreement": "Most people of your age <b>mostly disagree</b> with you.",
                "results_age_strong_disagreement": "Most people of your age <b>disagree</b> with you.",
                "results_age_first_vote": "You are <b>the first</b> of your age to answer this question!",
                "results_age_input_let_me_show_you": "Let me show you if people of your age agree with you!",
                "results_age_input_disclaimer": "Your age will not be disclosed to anyone.",

                "results_age_gender_first_vote_male": "You are <b>the first man</b> of your age to answer this question!",
                "results_age_gender_first_vote_female": "You are <b>the first woman</b> of your age to answer this question!",
                "results_age_gender_input_let_me_show_you": "Tell us your age and gender so that we can show you if people of your age and gender agree with you!",
                "results_age_gender_strong_agreement": "Most <b>{{ genderStr }}</b> of your age <b>agree</b> with you!",
                "results_age_gender_weak_agreement": "Most <b>{{ genderStr }}</b> of your age <b>mostly agree</b> with you!",
                "results_age_gender_weak_disagreement": "Most <b>{{ genderStr }}</b> of your age <b>mostly disagree</b> with you!",
                "results_age_gender_strong_disagreement": "Most <b>{{ genderStr }}</b> of your age <b>disagree</b> with you!",

                "qna_question_subtitle_single_choice":" Select <b>one</b> answer.",
                "qna_question_subtitle_multiple_choice": "Select <b>one or more</b> answers.",
                "qna_question_subtitle_limited_choice": "Select up to <b>{{answerLimit}}</b> answers.",
                "qna_question_subtitle_open_ended": "Write <b>your answer</b> in the text field below.",
                "qna_question_subtitle_other": "Select an option.",

                "footer_title1": "the customer feedback",
                "footer_title2": "system of Bulgaria.",
                "footer_learn_more": "Read more",
                "footer_here": "HERE",
            }
        },
        bg: {
            translation: {
                "general_male": 'мъж',
                "general_female": 'жена',
                "general_male_plural": 'мъже',
                "general_female_plural": 'жени',
                "general_error_message": "Съжаляваме, възникна грешка.",
                "general_rotate_device": "Завъртете устройството си",

                "button_back": "Назад",
                "button_confirm": 'Изпрати',
                "button_continue": 'Продължи',
                "button_skip": 'Пропусни',
                "label_you": "Вие",
                "label_please_choose": "Моля изберете",

                "image_upload_button_label": "Качи изображение",
                "image_upload_loading": "Качване на изображение",
                "image_upload_delete": "Изтрий изображение",
                
                "cookie_consent_title": "Разрешете бисквиткa",
                "cookie_consent_text1": "Целта на kazva.bg е да даде сила на мнението Ви!",
                "cookie_consent_text2": "Използваме единствено собствена бисквитка, за да гарантираме сигурността и тежестта на мненията, настройките и историята Ви.",
                "cookie_consent_according": "Прочети подробната ни",
                "cookie_consent_allow_cookie": "Разреши бисквитка",
                "cookie_consent_deny_cookie": "Откажи бисквитка",
                "cookie_consent_denied_title": "Отказахте бисквитката",
                "cookie_consent_denied_text1": "казва.бг не може да направи мнението Ви чуто без използването на нашата бисквитка, поради нормативни изисквания и съображения за сигурност.",
                "cookie_consent_denied_text2": "За да използвате казва.бг е необходимо да приемете нашата бисквитка. Тя е направена от нас с максимална грижа към Вашата сигурност и данни.",
                
                'first_vote_title': 'Честита Ви първа оценка!',
                'first_vote_text1': 'Това съобщение няма да се появи при следващите Ви оценки.',
                'first_vote_text2': 'След даване на оценка нямате възможност да се върнете назад, освен ако не рестартирате целия процес, за това моля изберете -',
                'first_vote_confirm': 'Потвърди оценка',
                'first_vote_try_again': 'Оцени отново',

                "input_placeholder_name": 'Вашето име',
                "input_placeholder_gender": 'Вашият пол',
                "input_placeholder_birth_year": 'Годината Ви на раждане',
                "input_placeholder_city": 'Град',
                "input_placeholder_region": 'Район',
                "input_placeholder_country": 'Държава',

                "home_message": "Здравейте, с нас виждате какво казва България за:",

                "menu_main_title": "Меню",
                "menu_main_profile": "Профил",
                "menu_main_about_us": "За нас",
                "menu_main_language": "Език",
                "menu_main_privacy_notice": "Политика за поверителност",

                "menu_profile_title": "Профил",
                "menu_profile_button_save": 'Запази промените',
                "menu_profile_button_discard": 'Откажи промените',
                "menu_profile_button_delete": 'Изтрий профил',
                "menu_profile_toast_success": "Профилът e запазен успешно.",
                "menu_profile_ays_title": "Сигурни ли сте, че искате да изтриете Вашия профил?",
                "menu_profile_ays_subtitle": "Това действие е необратимо!",
                "menu_profile_ays_button_yes": "Да",
                "menu_profile_ays_button_no": "Не",

                "menu_language_title": "Изберете език",

                "slider_subtitle": "кажете с плъзгане на топчето от 1 до 10",
                "slider_subtitle_vote_value": "Вие избрахте <b>{{ voteValue }} от 10</b>",
                "slider_saying_to": "казвате на",
                
                'qna_open_ended_disclaimer': 'С натискането на този бутон се съгласявате написаното да бъде предоставено на организацията, оперираща този канал за обратна връзка.',

                "end_screen_text": "Отговорихте на всички въпроси, благодарим Ви!",
                "end_screen_vote_again": "Кажете мнението си пак",
                "end_screen_copy_link": "Копирай линк към темата",
                "end_screen_copied_link" : "Копирано!",
                "end_screen_share_topic" : "Сподели въпроса с приятели",

                "results_majority_first_vote": "Вие сте <b>първият</b>, който отговаря на този въпрос!",
                "results_majority_strong_agreement": "Повечето хора са <b>съгласни</b> с Вас.",
                "results_majority_weak_agreement": "Повечето хора са <b>по-скоро съгласни</b> с Вас.",
                "results_majority_weak_disagreement": "Повечето хора са <b>по-скоро несъгласни</b> с Вас.",
                "results_majority_strong_disagreement": "Повечето хора са <b>несъгласни</b> с Вас.",

                "results_gender_strong_agreement": "Повечето <b>{{ genderStr }}</b> са <b>съгласни</b> с Вас!",
                "results_gender_weak_agreement": "Повечето <b>{{ genderStr }}</b> са <b>по-скоро съгласни</b> с Вас!",
                "results_gender_weak_disagreement": "Повечето <b>{{ genderStr }}</b> са <b>по-скоро несъгласни</b> с Вас!",
                "results_gender_strong_disagreement": "Повечето <b>{{ genderStr }}</b> са <b>несъгласни</b> с Вас!",
                "results_gender_first_vote_male": "Вие сте <b>първият мъж,</b> който отговаря на този въпрос!",
                "results_gender_first_vote_female": "Вие сте <b>първата жена,</b> която отговаря на този въпрос!",
                "results_gender_input_let_me_show_you": "Нека Ви покажа дали хората от Вашия пол са съгласни с Вас!",
                "results_gender_input_disclaimer": "Вашият пол няма да бъде споделен с никого.",

                "results_age_strong_agreement": "Повечето хора на Вашата възраст са <b>съгласни</b> с Вас.",
                "results_age_weak_agreement": "Повечето хора на Вашата възраст са <b>по-скоро съгласни</b> с Вас.",
                "results_age_weak_disagreement": "Повечето хора на Вашата възраст са <b>по-скоро несъгласни</b> с Вас.",
                "results_age_strong_disagreement": "Повечето хора на Вашата възраст са <b>несъгласни</b> с Вас.",
                "results_age_first_vote": "Вие сте <b>първият</b> на Вашата възраст, който отговаря на този въпрос!",
                "results_age_input_let_me_show_you": "Нека Ви покажа дали хората на Вашата възраст са съгласни с Вас!",
                "results_age_input_disclaimer": "Вашата възраст няма да бъде споделена с никого.",

                "results_age_gender_strong_agreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>съгласни</b> с Вас!",
                "results_age_gender_weak_agreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>по-скоро съгласни</b> с Вас!",
                "results_age_gender_weak_disagreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>по-скоро несъгласни</b> с Вас!",
                "results_age_gender_strong_disagreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>несъгласни</b> с Вас!",
                "results_age_gender_first_vote_male": "Вие сте <b>първият мъж</b> на Вашата възраст, който отговаря на този въпрос!",
                "results_age_gender_first_vote_female": "Вие сте <b>първата жена</b> на Вашата възраст, която отговаря на този въпрос!",
                "results_age_gender_input_let_me_show_you": "Изберете възраст и пол, за да Ви покажем дали хората от Вашия пол и на Вашата възраст са съгласни с Вас!",
                
                "qna_question_subtitle_single_choice":"Посочете <b>един</b> отговор.",
                "qna_question_subtitle_multiple_choice":"Посочете <b>един или повече</b> отговора.",
                "qna_question_subtitle_limited_choice":"Посочете до <b>{{answerLimit}}</b> отговора.",
                "qna_question_subtitle_open_ended": "Напишете <b>Вашият отговор</b> в полето по-долу.",
                "qna_question_subtitle_other": "Изберете опция.",

                "footer_title1": "българската система",
                "footer_title2": "за обратна връзка.",
                "footer_learn_more": "Разбери повече",
                "footer_here": "ТУК",
            }
        },
        de: {
            translation: {
                "general_male": 'männlich',
                "general_female": 'weiblich',
                "general_male_plural": 'Männer',
                "general_female_plural": 'Frauen',
                "general_error_message": "Entschuldigung, es ist ein Fehler aufgetreten.",
                "general_rotate_device": "Bitte drehen Sie Ihr Gerät",

                "button_back": "Zurück",
                "button_confirm": 'Senden',
                "button_continue": 'Weiter',
                "button_skip": 'Überspringen',
                "label_you": "Sie",
                "label_please_choose": "Bitte wählen",

                "image_upload_button_label": "Bild hochladen",
                "image_upload_loading": "Bild wird hochgeladen",
                "image_upload_delete": "Bild löschen",
 
                "cookie_consent_title": "Cookie erlauben",
                "cookie_consent_text1": "Der Zweck von kazva.bg ist es, Ihrer Meinung Gehör zu verschaffen!",
                "cookie_consent_text2": "Wir verwenden nur unseren eigenen Cookie, um die Sicherheit und das Gewicht Ihrer Meinungen, Einstellungen und Historie zu gewährleisten.",
                "cookie_consent_according": "Lesen Sie unsere",
                "cookie_consent_allow_cookie": "Cookie erlauben",
                "cookie_consent_deny_cookie": "Cookie ablehnen",
                "cookie_consent_denied_title": "Cookie abgelehnt",
                "cookie_consent_denied_text1": "kazva.bg kann Ihre Meinung ohne die Verwendung unseres Cookies aufgrund rechtlicher Anforderungen und Sicherheitsüberlegungen nicht berücksichtigen.",
                "cookie_consent_denied_text2": "Um kazva.bg nutzen zu können, müssen Sie unseren Cookie akzeptieren. Er wurde von uns mit größter Sorgfalt für Ihre Sicherheit und Daten erstellt.",
                
                'first_vote_title': 'Herzlichen Glückwunsch zu Ihrer ersten Abstimmung!',
                'first_vote_text1': 'Diese Nachricht wird bei Ihren nachfolgenden Abstimmungen nicht mehr erscheinen.',
                'first_vote_text2': 'Nach der Abstimmung können Sie nicht mehr zurückgehen, es sei denn, Sie starten den gesamten Prozess neu. Bitte wählen Sie -',
                'first_vote_confirm': 'Abstimmung bestätigen',
                'first_vote_try_again': 'Erneut versuchen',
                
                "input_placeholder_name": 'Ihr Name',
                "input_placeholder_gender": 'Ihr Geschlecht',
                "input_placeholder_birth_year": 'Ihr Geburtsjahr',
                "input_placeholder_city": 'Stadt',
                "input_placeholder_region": 'Region',
                "input_placeholder_country": 'Land',

                "home_message": "Hallo, bei uns sehen Sie, was Bulgarien sagt über:",

                "menu_main_title": "Menü",
                "menu_main_profile": "Profil",
                "menu_main_about_us": "Über uns",
                "menu_main_language": "Sprache",
                "menu_main_privacy_notice": "Datenschutzhinweis",

                "menu_profile_title": "Profil",
                "menu_profile_button_save": 'Änderungen speichern',
                "menu_profile_button_discard": 'Änderungen verwerfen',
                "menu_profile_button_delete": 'Profil löschen',
                "menu_profile_toast_success": "Profil erfolgreich gespeichert.",
                "menu_profile_ays_title": "Sind Sie sicher, dass Sie Ihr Profil löschen möchten?",
                "menu_profile_ays_subtitle": "Diese Aktion ist unwiderruflich!",
                "menu_profile_ays_button_yes": "Ja",
                "menu_profile_ays_button_no": "Nein",

                "menu_language_title": "Sprache auswählen",

                "slider_subtitle": "Sagen Sie es uns, indem Sie den Ball von 1 bis 10 schieben",
                "slider_subtitle_vote_value": "Sie haben <b>{{ voteValue }} von 10</b> gewählt",
                "slider_saying_to": "sagen zu",

                'qna_open_ended_disclaimer': 'Durch Drücken dieser Schaltfläche stimmen Sie zu, dass Ihr Text der Organisation, die diesen Kanal betreibt, für Feedback zur Verfügung gestellt wird.',
            
                "end_screen_vote_again": "Geben Sie Ihre Meinung erneut ab",
                "end_screen_copy_link": "Link zum Thema kopieren",
                "end_screen_copied_link" : "Kopiert!",
                "end_screen_share_topic" : "Thema mit Freunden teilen",

                "results_majority_first_vote": "Sie sind <b>der Erste</b>, der diese Frage beantwortet!",
                "results_majority_strong_agreement": "Die meisten Menschen <b>stimmen</b> Ihnen zu.",
                "results_majority_weak_agreement": "Die meisten Menschen <b>stimmen Ihnen größtenteils zu</b>.",
                "results_majority_weak_disagreement": "Die meisten Menschen <b>stimmen Ihnen größtenteils nicht zu</b>.",
                "results_majority_strong_disagreement": "Die meisten Menschen <b>stimmen Ihnen nicht zu</b>.",

                "results_gender_strong_agreement": "Die meisten <b>{{ genderStr }}</b> <b>stimmen</b> Ihnen zu!",
                "results_gender_weak_agreement": "Die meisten <b>{{ genderStr }}</b> <b>stimmen Ihnen größtenteils zu</b>!",
                "results_gender_weak_disagreement": "Die meisten <b>{{ genderStr }}</b> <b>stimmen Ihnen größtenteils nicht zu</b>!",
                "results_gender_strong_disagreement": "Die meisten <b>{{ genderStr }}</b> <b>stimmen Ihnen nicht zu</b>!",
                "results_gender_first_vote_male": "Sie sind <b>der erste Mann</b>, der diese Frage beantwortet!",
                "results_gender_first_vote_female": "Sie sind <b>die erste Frau</b>, die diese Frage beantwortet!",
                "results_gender_input_let_me_show_you": "Lassen Sie mich Ihnen zeigen, ob Menschen Ihres Geschlechts Ihnen zustimmen!",
                "results_gender_input_disclaimer": "Ihr Geschlecht wird niemandem offengelegt.",

                "results_age_strong_agreement": "Die meisten Menschen in Ihrem Alter <b>stimmen</b> Ihnen zu.",
                "results_age_weak_agreement": "Die meisten Menschen in Ihrem Alter <b>stimmen Ihnen größtenteils zu</b>.",
                "results_age_weak_disagreement": "Die meisten Menschen in Ihrem Alter <b>stimmen Ihnen größtenteils nicht zu</b>.",
                "results_age_strong_disagreement": "Die meisten Menschen in Ihrem Alter <b>stimmen Ihnen nicht zu</b>.",
                "results_age_first_vote": "Sie sind <b>der Erste</b> in Ihrem Alter, der diese Frage beantwortet!",
                "results_age_input_let_me_show_you": "Lassen Sie mich Ihnen zeigen, ob Menschen in Ihrem Alter Ihnen zustimmen!",
                "results_age_input_disclaimer": "Ihr Alter wird niemandem offengelegt.",

                "results_age_gender_first_vote_male": "Sie sind <b>der erste Mann</b> in Ihrem Alter, der diese Frage beantwortet!",
                "results_age_gender_first_vote_female": "Sie sind <b>die erste Frau</b> in Ihrem Alter, die diese Frage beantwortet!",
                "results_age_gender_input_let_me_show_you": "Nennen Sie uns Ihr Alter und Geschlecht, damit wir Ihnen zeigen können, ob Menschen Ihres Alters und Geschlechts Ihnen zustimmen!",
                "results_age_gender_strong_agreement": "Die meisten <b>{{ genderStr }}</b> in Ihrem Alter <b>stimmen</b> Ihnen zu!",
                "results_age_gender_weak_agreement": "Die meisten <b>{{ genderStr }}</b> in Ihrem Alter <b>stimmen Ihnen größtenteils zu</b>!",
                "results_age_gender_weak_disagreement": "Die meisten <b>{{ genderStr }}</b> in Ihrem Alter <b>stimmen Ihnen größtenteils nicht zu</b>!",
                "results_age_gender_strong_disagreement": "Die meisten <b>{{ genderStr }}</b> in Ihrem Alter <b>stimmen Ihnen nicht zu</b>!",

                "qna_question_subtitle_single_choice":"Wählen Sie <b>eine</b> Antwort aus.",
                "qna_question_subtitle_multiple_choice":"Wählen Sie <b>eine oder mehrere</b> Antworten aus.",
                "qna_question_subtitle_limited_choice":"Wählen Sie bis zu <b>{{answerLimit}}</b> Antworten aus.",
                "qna_question_subtitle_open_ended": "Schreiben Sie <b>Ihre Antwort</b> in das untenstehende Feld.",
                "qna_question_subtitle_other": "Wählen Sie eine Option aus.",

                "footer_title1": "das Kundenfeedback",
                "footer_title2": "System von Bulgarien.",
                "footer_learn_more": "Mehr lesen",
                "footer_here": "HIER",
            }
        },
    },
    detection: {
        order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage'],
    },
    fallbackLng: "bg",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;