import React, { useContext, useState } from 'react';

import { Answer } from '@kazvabg/voterapp-model';
import { SubmitAnswerFunction } from '../QuestionAndAnswer';
import StyledButton from '../../../components/StyledButton';

import styled from 'styled-components';
import Fade from '../../../components/Fade';
import OptionsButton from '../../../components/OptionsButton';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../App';

interface CheckboxRowProps {
    isSelected: boolean;
}

const CheckboxRow = styled.div<CheckboxRowProps>`
    display: block;
    color: white;
    vertical-align: top;
    margin-bottom: 13px;

    ${props => (
        props.isSelected ? `
            background:linear-gradient(90deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.25) 100%);
            border-radius: 4px;
        ` : ""
    )}
    
    &:hover {
        cursor: pointer;
    }

    input[type='checkbox'] {
        display: none;
    }
    
    input[type='checkbox']:disabled + label {
        color: #A6A6A6;
    }

    input[type='checkbox']:disabled + label::before {
        border-radius: 3.3888px;
        border: 1.37px solid #b3b3b3;
        background: #fff4;
        font-size: 1rem;
        box-shadow: 0px 0px 0px
    }

    input[type='checkbox'] + label {
        cursor: pointer;
        vertical-align: top;
        display: block;
        font-size: 1rem;
        padding: 13px;
        position: relative;
    }

    input[type='checkbox'] + label::before {
        margin-right:14px;
        content: '';
        width: 30px;
        height: 30px;
        display: inline-block;
        border-radius: 3.3888px;
        border: 1.37px solid #FFF;
        background: #ffffff0d;
        vertical-align: top;
    }
    
    input[type='checkbox']:checked + label::before {
        border-radius: 3.3888px;
        border: 1.37px solid #FFF;
        background: #49596B;
        font-size: 1rem;
        box-shadow: 0px 0px 0px 3.441px rgba(255, 255, 255, 0.15);
    }

    .checkbox-text {
        display: inline-block;
        width: calc(100% - 50px);
        padding-top: 5px;
    }

    .checkmark-container {
        position: absolute;
        top: 13px;
        left: 13px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
`;

interface AnswerMultiSelectProps {
    questionId: number;
    question: string;
    answers: Answer[];
    submitAnswer: SubmitAnswerFunction;
    answerLimit: number;
}

const MultiSelect: React.FC<AnswerMultiSelectProps> = (props) => {

    const [selectedList, setSelectedList] = useState<boolean[]>(props.answers.map(() => false));

    const { topic } = useContext(AppContext);

    const { t, i18n } = useTranslation();

    const selectAnswer = (i: number) => {
        selectedList[i] = !selectedList[i];
        setSelectedList([...selectedList]);
    };

    const buttonClicked = () => {
        let answersString = '';

        for (let i = 0; i < selectedList.length; i++) {
            if (selectedList[i]) {
                answersString += `${props.answers[i].text}/%/`
            }
        }

        props.submitAnswer({ answer: answersString });
    };

    const getAnswerText = (answer: Answer, index: number) => {

        let answerText = answer.text;

        const currentLanguage = i18n.language;

        if (currentLanguage !== topic?.defaultLanguage && topic?.altLngs.includes(currentLanguage)) {
            answerText = topic?.translations[currentLanguage].qna[props.questionId ?? 0].answers[index + 1].text;
        }

        return answerText;
    }

    const answerLimitReached = props.answerLimit > 1 && selectedList.filter(Boolean).length >= props.answerLimit;
    const atLeastOneAnswerSelected = selectedList.some(Boolean);

    return (
        <Fade cascade>
            <div style={{ background: '#00000055', marginBottom: '20px', padding: '13px', borderRadius: '10px' }}>
                <Fade cascade>
                    {props.answers.map((answer, i) =>
                        <CheckboxRow key={answer.answerId} isSelected={selectedList[i]}>
                            <input
                                type="checkbox"
                                id={answer.answerId.toString()}
                                name={answer.answerId.toString()}
                                value={answer.text}
                                onClick={() => selectAnswer(i)}
                                disabled={!selectedList[i] && answerLimitReached}
                            />
                            <label htmlFor={answer.answerId.toString()}>
                                {selectedList[i] && (
                                    <div className="checkmark-container">
                                        {!answerLimitReached
                                            ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 12 9" fill="none">
                                                <path
                                                    d="M10.5 1.625L4.3125 7.8125L1.5 5"
                                                    stroke="white"
                                                    strokeWidth="1.71429"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
                                                <path
                                                    d="M2.71423 7H11.7142"
                                                    stroke="white"
                                                    strokeWidth="1.71429"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        }
                                    </div>
                                )}
                                <div className='checkbox-text'>{getAnswerText(answer, i)}</div>
                            </label>
                        </CheckboxRow>
                    )}
                </Fade>
            </div>
            <Fade>
                <StyledButton disabled={!atLeastOneAnswerSelected} onClick={buttonClicked}>
                    {t('button_confirm')}
                </StyledButton>
            </Fade>
        </Fade>
    );
};

export default MultiSelect;