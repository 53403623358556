import axios, { AxiosError } from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import TopicComponent from './pages/Topic';

import styled, { createGlobalStyle } from 'styled-components';
import { PreciseCoordinates, TopicV2 } from '@kazvabg/voterapp-model';
import Confetti from './effects/Confetti';

import RotateMe from './components/RotateMe';
import PolicyScreen from './menu/PolicyScreen';
import GlassPanel from './components/GlassPanel';
import MainMenu from './menu/MainMenu';
import ProfileComponent from './menu/Profile';
import LanguageScreen from './menu/LanguageScreen';

import { Toaster } from 'react-hot-toast';
import DeleteProfileAYSScreen from './menu/profile/DeleteProfileAYSScreen';

import './i18n';
import LoadingTopicScreen from './components/LoadingTopicScreen';

const GlobalStyle = createGlobalStyle`

    body, input, button {
        margin: 0;
        //font-family: Raleway, sans-serif;
        font-family: sans-serif;
    }

    select, option {
        //font-family: Raleway, sans-serif;
    }

    h3 {
        font-size: 28px;
        font-weight: bold;
    }
    
    p {
        line-height: 1.7;
        font-size: 22px;
        font-weight: 300;
        color: #333;
        b {
            font-weight: 999;
        }
    }
    a:active, a:focus {
        outline: 0;
        border: none;
        -moz-outline-style: none;
    }
`;

const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    transition: filter 0.6s ease;
    background-color: black;
`;

const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
    z-index: -2;
    z-index: -2;
    display: block;

    @media (orientation: portrait) {
        display: none;
    }
`;

const BackgroundImagePortrait = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
    z-index: -2;

    @media (orientation: portrait) {
        display: block;
    }
`;

const AppContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

export type GenderData = 'male' | 'female' | 'other' | null;

export enum MenuScreen {
    None = 'none',
    MainMenu = 'main-menu',
    Language = 'language',
    Profile = 'profile',
    PrivacyNotice = 'privacy-notice',
    PrivacyNoticeMenu = 'privacy-notice-menu',
    Consent = 'consent',
    DeleteProfileAYS = 'delete-profile-ays'
}

export interface ProfileData {
    userId: string;
    locale: string;
    name: string;
    gender: GenderData;
    birthYear: number | null;
    cityName: string;
    regionName: string;
    countryName: string;
}

const emptyProfile: ProfileData = {
    userId: '',
    locale: '',
    name: '',
    gender: null,
    birthYear: null,
    cityName: '',
    regionName: '',
    countryName: ''
};

interface AppContextType {
    apiUrl: string;
    profile: ProfileData | null;
    setProfile: (profile: ProfileData | null) => void;
    topic: TopicV2 | null;
    showResults: boolean;
    setTopic: (topic: TopicV2 | null) => void;
    menuScreen: MenuScreen;
    setMenuScreen: (menuScreen: MenuScreen) => void;
    setGlassPanelColor: (color: string) => void;
    setGlassPanelBackdropFilter: (blur: number, saturate: number, brightness: number) => void;
    setLoading: (loading: boolean) => void;
    geolocationConsent: 'granted' | 'denied' | 'prompt' | null;
    setGeolocationConsent: (geolocationConsent: GeolocationConsent) => void;
    preciseCoordinates?: PreciseCoordinates;
    updatePreciseLocation: () => Promise<void>;
    revertSlider: () => void;
    setRevertSlider: (revertSlider: () => void) => void;
    triggerConfetti: () => void;
}

export const AppContext = React.createContext<AppContextType>({
    apiUrl: '',
    profile: null,
    setProfile: () => { throw new Error("Function not implemented"); },
    topic: null,
    showResults: false,
    setTopic: () => { throw new Error("Function not implemented"); },
    menuScreen: MenuScreen.None,
    setMenuScreen: () => { throw new Error("Function not implemented"); },
    setGlassPanelColor: () => { throw new Error("Function not implemented") },
    setGlassPanelBackdropFilter: () => { throw new Error("Function not implemented") },
    setLoading: () => { throw new Error("Function not implemented") },
    geolocationConsent: null,
    setGeolocationConsent: () => { throw new Error("Function not implemented") },
    updatePreciseLocation: () => { throw new Error("Function not implemented") },
    revertSlider: () => { throw new Error("Function not implemented") },
    setRevertSlider: () => { throw new Error("Function not implemented") },
    triggerConfetti: () => { throw new Error("Function not implemented") }
});

type GeolocationConsent = 'granted' | 'denied' | 'prompt' | null;

interface AppState {
    loading: boolean;
    profile: ProfileData | null;
    topic: TopicV2 | null;
    showResults: boolean;
    menuScreen: MenuScreen;
    geolocationConsent: GeolocationConsent;
    preciseCoordinates?: PreciseCoordinates;
    cookieConsent: 'needs' | 'granted' | 'create-cookie' | 'unknown';
    confettiActive: boolean;
}

const getApiURL = () => {
    if(process.env.NODE_ENV === 'production') {
        return 'https://prod.kazva.bg/api/v1';
    } else if(process.env.REACT_APP_STAGING) {
        return 'https://staging-api.kazva.bg/api/v1';
    } else if(process.env.NODE_ENV === 'development') {
        // this allows for connections on the local network
        return `http://${window.location.hostname}:8000/api/v1`;
    }
    
    return 'http://localhost:8000/api/v1';
};

const App: React.FC = () => {

    const apiUrl = getApiURL();

    const glassPanelRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<AppState>(() => ({
        loading: true,
        profile: null,
        topic: null,
        showResults: false,
        menuScreen: MenuScreen.None,
        geolocationConsent: null,
        cookieConsent: 'unknown',
        confettiActive: false
    }));
    const stateRef = useRef<AppState>(state);

    const setTopic = (newTopic: TopicV2 | null) => {

        const showResults: boolean | undefined =
            newTopic?.pvt.showPeopleStats ||
            newTopic?.pvt.showGenderStats ||
            newTopic?.pvt.showAgeStats ||
            newTopic?.pvt.showGenderByAgeStats;

        stateRef.current = { ...stateRef.current, topic: newTopic, showResults: !!showResults };
        setState(stateRef.current);
    };

    const updatePreciseLocation = () => new Promise<void>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
                
            setPreciseCoordinates(position.coords);
            if(state.geolocationConsent !== 'granted')
                setGeolocationConsent('granted');
            resolve();

        }, (error) => { reject(error) }, {
            enableHighAccuracy: true, // Request precise location
            timeout: 5000, // Wait up to 5 seconds
            maximumAge: 0, // Do not use cached location
        });
    });

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {

            let geolocationConsent: 'granted' | 'denied' | 'prompt';

            if (permissionStatus.state === 'granted') {
                geolocationConsent = 'granted';
                updatePreciseLocation();
            } else if (permissionStatus.state === 'denied') {
                geolocationConsent = 'denied';
            } else {
                geolocationConsent = 'prompt';
            }

            stateRef.current = {...stateRef.current, geolocationConsent }
            setState(stateRef.current);
        });
    }, []);

    /*useEffect(() => {
        console.log('Geolocation consent changed to', state.geolocationConsent);
        //if(state.geolocationConsent === 'granted' && !state.preciseCoordinates) {

        //}
    }, [state.geolocationConsent]);*/

    /*useEffect(() => {
        console.log('Precise coordinates set to', state.preciseCoordinates);
    }, [state.preciseCoordinates]);*/

    const setGeolocationConsent = (geolocationConsent: GeolocationConsent) => {
        stateRef.current = {...stateRef.current, geolocationConsent }
        setState(stateRef.current);
    };

    const setPreciseCoordinates = (coords: GeolocationCoordinates) => {
        stateRef.current = {...stateRef.current, preciseCoordinates: {
            acc: coords.accuracy,
            alt: coords.altitude,
            altAcc: coords.altitudeAccuracy,
            lat: coords.latitude,
            lon: coords.longitude,
        }};
        setState(stateRef.current);
    };

    const authenticateUser = (profile: ProfileData) => {
        stateRef.current = { ...stateRef.current, profile, cookieConsent: 'granted' };
        setState(stateRef.current);
        window._paq.push(['setUserId', profile.userId]);
    }

    const setCookieConsent = (cookieConsent: 'needs' | 'granted' | 'create-cookie' | 'unknown') => {
        stateRef.current = { ...stateRef.current, cookieConsent };
        setState(stateRef.current);
    }

    useEffect(() => {
        if(!state.profile) {
            if(state.cookieConsent === 'granted') {
                axios.get(`${apiUrl}/user`).then(res => {
                    authenticateUser(res.data.profile);
                }); 
            } else if(state.cookieConsent === 'create-cookie') {
                axios.post(`${apiUrl}/user`).then(res => {
                    authenticateUser(res.data.profile);
                });
            }
        }
    }, [state.cookieConsent]);

    const setGlassPanelColor = (color: string) => {
        if (glassPanelRef.current) {
            glassPanelRef.current.style.backgroundColor = color;
        }
    };

    const setGlassPanelBackdropFilter = (blur: number, saturate: number, brightness: number) => {
        if (glassPanelRef.current) {
            const styleObject = glassPanelRef.current.style as any;
            styleObject.backdropFilter = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
            styleObject['-webkit-backdrop-filter'] = `blur(${blur}px) saturate(${saturate}) brightness(${brightness})`;
        }
    };

    const renderMenuScreen = () => {
        switch (state.menuScreen) {
            case MenuScreen.None:
                return null;
            case MenuScreen.PrivacyNotice:
                return <PolicyScreen backClicked={() => setMenuScreen(MenuScreen.Consent)} />;
            case MenuScreen.PrivacyNoticeMenu:
                return <PolicyScreen backClicked={() => setMenuScreen(MenuScreen.MainMenu)} />;
            case MenuScreen.MainMenu:
                return <MainMenu />;
            case MenuScreen.Profile:
                return <ProfileComponent />;
            case MenuScreen.Language:
                return <LanguageScreen />
            case MenuScreen.DeleteProfileAYS:
                return <DeleteProfileAYSScreen />

            default: return null;
        }
    };

    const setProfile = (profile: ProfileData | null) => {
        stateRef.current = { ...stateRef.current, profile };
        setState(stateRef.current);
    };

    const setLoading = (loading: boolean) => {
        stateRef.current = { ...stateRef.current, loading };
        setState(stateRef.current);
    };

    const setMenuScreen = (menuScreen: MenuScreen) => {
        stateRef.current = { ...stateRef.current, menuScreen };
        setState(stateRef.current);
    };

    const getBackgroundImage = () => {
        if (!state.topic)
            return '';

        if (state.topic.backgroundUrl)
            return state.topic.backgroundUrl;

        return state.topic.image;
    };

    const getBackgroundImagePortrait = () => {
        if (!state.topic)
            return '';

        if (state.topic.backgroundPortraitUrl)
            return state.topic.backgroundPortraitUrl;

        if (state.topic.backgroundUrl)
            return state.topic.backgroundUrl;

        return state.topic.image;
    };

    const revertSliderRef = useRef<() => void>(() => undefined);

    const setRevertSlider = (revertSlider: () => void) => {
        revertSliderRef.current = revertSlider;
    };

    const triggerConfetti = () => {
        stateRef.current = { ...stateRef.current, confettiActive: true };
        setState(stateRef.current);

        setTimeout(() => {
            stateRef.current = { ...stateRef.current, confettiActive: false };
            setState(stateRef.current);
        }, 5000);
    };

    return (
        <AppContext.Provider value={{
            apiUrl,
            profile: state.profile,
            setProfile,
            topic: state.topic, setTopic,
            showResults: state.showResults,
            menuScreen: state.menuScreen, setMenuScreen,
            setGlassPanelColor, setGlassPanelBackdropFilter,
            setLoading,
            geolocationConsent: state.geolocationConsent,
            setGeolocationConsent,
            preciseCoordinates: state.preciseCoordinates,
            updatePreciseLocation,
            revertSlider: revertSliderRef.current,
            setRevertSlider,
            triggerConfetti
        }}>
            <GlobalStyle />
            <RotateMe />
            <Toaster />
            <Confetti isActive={state.confettiActive} />
            <AppContainer>
                {/*<BackgroundImage src={state.topic?.ti ? state.topic.ti : '/images/gradient-backgrounds/GradientTextures-04.jpg'} />*/}
                <GlassPanel style={{ backgroundColor: '#000' }} ref={glassPanelRef}>
                    {renderMenuScreen()}
                    <div style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        display: state.menuScreen === MenuScreen.None ? 'block' : 'none'
                    }}>
                        <BrowserRouter>
                            <Switch>
                                <Route path='/:topicHash' component={TopicComponent} />
                                <Route exact path='/' component={Home} />
                            </Switch>
                        </BrowserRouter>
                    </div>
                </GlassPanel>
                <LoadingTopicScreen isLoading={state.loading} />
                <BackgroundContainer>
                    <BackgroundImage onLoad={() => { if (stateRef.current.loading) setLoading(false) }} src={getBackgroundImage()} />
                    <BackgroundImagePortrait onLoad={() => { if (stateRef.current.loading) setLoading(false) }} src={getBackgroundImagePortrait()} />
                </BackgroundContainer>
            </AppContainer>
        </AppContext.Provider >
    );
};

export default App;