const PrivacyPolicyContentENG: React.FC = () => {
    return (
        <div>
            <h1>PRIVACY POLICY OF CNTS LTD FOR KAZVA.BG</h1>

            <p>Last Updated: 26.03.2025</p>

            <h2>I. ABOUT US</h2>

            <p>CNTS Ltd. (hereinafter "CNTS," "we," "us," "our") is a company offering comprehensive services in the field of marketing and social research, as well as consulting. CNTS created and operates kazva.bg ("the Platform," "kazva.bg") as a tool for any individual to quickly and conveniently share their opinion on a given topic and see how that opinion compares to others. When the topic involves products, services, or initiatives of a third party (a CNTS client), kazva.bg provides an opportunity for each user's anonymized feedback to be effectively heard by the relevant organization's high-level staff.</p>

            <p>If you wish to learn more about us or send a business inquiry, you can visit our website cnts.bg or email us at privacy@cnts.bg.</p>

            <hr />

            <h2>II. APPLICABLE LEGISLATION</h2>

            <p>This Privacy Policy has been prepared in accordance with:</p>
            <ul>
                <li>The General Data Protection Regulation (Regulation (EU) 2016/679, "GDPR");</li>
                <li>The Bulgarian Personal Data Protection Act and other applicable laws in the Republic of Bulgaria.</li>
            </ul>

            <hr />

            <h2>III. ROLES: DATA CONTROLLER AND/OR DATA PROCESSOR</h2>

            <p>CNTS Ltd. acts as the data controller with regard to the personal data provided by kazva.bg users upon registration and interaction with the Platform. CNTS' clients (third parties) who may receive anonymized feedback can act as either joint controllers or processors of personal data if the user expressly consents to sharing their contact information for direct follow-up.</p>

            <hr />

            <h2>IV. TYPES OF PERSONAL DATA WE COLLECT</h2>

            <p>CNTS, through kazva.bg, only collects data that the user voluntarily provides about themselves, which may include:</p>
            <ul>
                <li>Contact details: name, address, phone, email address (work or personal), if the user wishes to provide them;</li>
                <li>Basic demographic data: year of birth, gender, location;</li>
                <li>Additional data (where provided for in the Platform): other demographic or profile information (e.g., education), again only if the user voluntarily completes it.</li>
            </ul>

            <p><strong>Important:</strong> Users submit data solely about themselves. The Platform is not intended for entering data about third parties.</p>

            <hr />

            <h2>V. AGE RESTRICTION</h2>

            <p>The kazva.bg Platform is not intended for children under the age of 16. If we become aware that we have inadvertently collected data from a user under 16, we will delete such information as soon as we are made aware of it.</p>

            <hr />

            <h2>VI. PURPOSES AND LEGAL BASES FOR PROCESSING</h2>
            <ol>
                <li>
                    <p>Providing the Service</p>
                    <ul>
                        <li>To allow the user to register and utilize the features of kazva.bg. We process personal data on the basis of contractual necessity (Article 6(1)(b) of the GDPR).</li>
                    </ul>
                </li>
                <li>
                    <p>Consent</p>
                    <ul>
                        <li>Where a user expressly wishes to share personal data (e.g., name, email, phone) with a third-party client (for instance, to receive a response or participate in an initiative), we do so only under explicit consent (Article 6(1)(a) of the GDPR).</li>
                    </ul>
                </li>
                <li>
                    <p>Legal Obligation</p>
                    <ul>
                        <li>Where we are required to comply with a statutory obligation (e.g., accounting, taxation, or other legal requirement), we process your data in accordance with Article 6(1)(c) of the GDPR.</li>
                    </ul>
                </li>
                <li>
                    <p>Legitimate Interest</p>
                    <ul>
                        <li>In certain cases, we may process data based on our legitimate interest in improving our services or ensuring the security of the Platform (Article 6(1)(f) of the GDPR), unless your fundamental rights and freedoms override this interest.</li>
                    </ul>
                </li>
            </ol>

            <hr />

            <h2>VII. HOW WE COLLECT DATA</h2>
            <ol>
                <li>Registration on kazva.bg – when the user creates an account and provides basic information (e.g., email, password).</li>
                <li>Use of the Platform – by participating in surveys, giving answers to questions, voting, filling out a profile, etc.</li>
                <li>Contacting Us – if you send queries or requests via email to CNTS.</li>
            </ol>

            <hr />

            <h2>VIII. DISCLOSURE OF DATA TO THIRD PARTIES</h2>
            <ol>
                <li>
                    <p>Anonymized Data for Clients</p>
                    <ul>
                        <li>By default, any data provided to clients (third parties) is anonymized and does not enable direct identification of users.</li>
                    </ul>
                </li>
                <li>
                    <p>Disclosure Only Upon Explicit Consent</p>
                    <ul>
                        <li>If a user explicitly consents to the sharing of their contact details (name, email, phone, etc.) with a specific client, that disclosure is only for that specific purpose. The user is clearly informed of the organization receiving the data.</li>
                    </ul>
                </li>
            </ol>

            <hr />

            <h2>IX. DATA RETENTION PERIOD</h2>

            <p>We retain your personal data for as long as your kazva.bg account is active or as needed to fulfill the above purposes. Once your account is deleted, your personal data will be erased or anonymized within up to 180 days, unless there is a legal obligation to retain it longer.</p>

            <hr />

            <h2>X. DATA SECURITY AND BREACH NOTIFICATION</h2>
            <ul>
                <li>Servers in the EU: All data is stored on servers located within the EU, in encrypted form, subject to strict access controls.</li>
                <li>No International Transfer: We do not transfer personal data outside of the EU/EEA.</li>
                <li>Security Measures: We use encryption protocols (SSL/TLS), access control, and regular vulnerability checks.</li>
                <li>Breach Notification: If we become aware of a security breach that could pose a risk to your rights and freedoms, we will notify the competent supervisory authority (the Bulgarian Commission for Personal Data Protection) within 72 hours, as well as affected individuals where applicable.</li>
            </ul>

            <hr />

            <h2>XI. COOKIES</h2>
            <ul>
                <li>"kazvabg_cookie": The kazva.bg Platform uses only its own encrypted cookie for the following purposes:</li>
                <li>Recognizing the user;</li>
                <li>Providing core functionalities (saving preferences, opinion history, etc.);</li>
                <li>Preventing malicious activity.</li>
                <li>No Additional Cookies: We do not use Google Analytics, marketing, or other external cookies.</li>
                <li>You can delete or block cookies at any time through your browser settings, but doing so may limit some functionalities of the Platform.</li>
            </ul>

            <hr />

            <h2>XII. DATA SUBJECT RIGHTS</h2>

            <p>Under the GDPR, you have the following rights regarding your personal data:</p>
            <ol>
                <li>Right of Access: To obtain confirmation whether your personal data is being processed and to receive a copy thereof.</li>
                <li>Right to Rectification: To request the correction of inaccurate data or the completion of incomplete data.</li>
                <li>Right to Erasure ("Right to be Forgotten"): To request the deletion of your personal data where there is no legal or contractual need to retain it.</li>
                <li>Right to Restrict Processing: To request temporary restriction of processing in certain circumstances.</li>
                <li>Right to Object: To object to processing where it is based on a legitimate interest, unless we can demonstrate compelling legitimate grounds for such processing.</li>
                <li>Right to Data Portability: To receive your data in a structured, commonly used, and machine-readable format, and to request its transfer to another controller, where technically feasible.</li>
                <li>Right to Withdraw Consent: Where processing is based on your consent, you may withdraw that consent at any time.</li>
            </ol>

            <hr />

            <h2>XIII. HOW TO EXERCISE YOUR RIGHTS</h2>
            <ul>
                <li>Self-Deletion: You can delete your account and data directly through your account settings in kazva.bg.</li>
                <li>By Email: You can send a request to privacy@cnts.bg with a brief description of which right(s) you wish to exercise and sufficient information to identify you (e.g., the email address you registered with).</li>
                <li>By Post: Bulgaria, Sofia, Dunav St. No. 44 (CNTS Ltd.).</li>
            </ul>

            <p>We will confirm receipt of your request and respond within one month, unless we need more time (in which case we will let you know promptly).</p>

            <hr />

            <h2>XIV. MARKETING COMMUNICATIONS</h2>

            <p>We do not send any marketing or promotional communications and do not use your data for such purposes. We will only contact you when necessary for support, technical updates, or to respond to your inquiries.</p>

            <hr />

            <h2>XV. POLICY VERSION AND CHANGES</h2>

            <p>We do not maintain a public change log. If we make substantial changes to this Policy, we will notify you via a notice on the kazva.bg Platform. The date of the last update is indicated at the end of this document.</p>

            <hr />

            <h2>XVI. RIGHT TO LODGE A COMPLAINT</h2>

            <p>If you believe your data protection rights have been violated, you have the right to file a complaint with the Commission for Personal Data Protection (CPDP) in Bulgaria (www.cpdp.bg). We would, however, appreciate the chance to address your concerns first if you reach out to us at privacy@cnts.bg.</p>

            <hr />

            <h2>XVII. CONTACT US</h2>
            <ul>
                <li>Address: Bulgaria, Sofia, Dunav St. No. 44 (CNTS Ltd.)</li>
                <li>Email: privacy@cnts.bg</li>
            </ul>

            <hr />

            <p>&copy; 2025 CNTS Ltd. All rights reserved.</p>
        </div>
    );
};

export default PrivacyPolicyContentENG;
