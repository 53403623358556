import ConfettiComponent from 'react-confetti-boom';

interface ConfettiProps {
    isActive: boolean;
}

const Confetti: React.FC<ConfettiProps> = (props) => {
    return (!props.isActive ? null :
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1005,
            pointerEvents: 'none'
        }}>
            <ConfettiComponent
                mode='boom'
                particleCount={200}
                colors={['#FF5733', '#33FF57', '#3357FF', '#FF33F5', '#F5FF33']}
                spreadDeg={180}
            />
        </div>
    );
};

export default Confetti;