import { useContext } from "react";

import { AppContext, MenuScreen } from "../App";

import PrivacyPolicyContent from "../legal/PrivacyPolicyContent";

import StyledButton from "../components/StyledButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StyledContentPanel from "../components/StyledContentPanel";

const PolicyScreenStyle = styled(StyledContentPanel)`
    h1 {
        margin: 20px 0;
        font-size: 24px;
    }

    h2 {
        margin: 20px 0;
        font-size: 17px;//24px;
    }

    h3 {
        margin: 20px 0;
        font-size: 17px;
    }

    h4 {
        margin: 20px 0;
        font-size: 15px;
    }

    p, li {
        font-size: 15px;
        color: white;
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.7;
        font-weight: normal;
    }

    hr {
        margin: 20px 0;
        border: 0;
        height: 2px;
        background: rgba(255, 255, 255, 0.3);
    }
`;

const PolicyScreen: React.FC<{ backClicked: () => void }> = (props) => {

    const { t } = useTranslation();

    return (
        <div style={{ padding: '20px' }}>
            <StyledButton onClick={props.backClicked}>
                <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                {t('button_back')}
            </StyledButton>
            <PolicyScreenStyle>
                <PrivacyPolicyContent />
            </PolicyScreenStyle>
            <StyledButton onClick={props.backClicked}>
                <span style={{ marginRight: '10px' }}><FontAwesomeIcon icon={faArrowLeft} /></span>
                {t('button_back')}
            </StyledButton>
        </div>
    );
};

export default PolicyScreen;