const PrivacyPolicyDiv: React.FC = () => {
    return (
        <>
            <h1>ПОЛИТИКА ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ НА ЦНТС ООД ЗА KAZVA.BG</h1>

            <h2>I. ЗА НАС</h2>

            <p>Център за Нови Технологични Системи („ЦНТС" ООД) е компания, предлагаща цялостно обслужване в сферата на маркетинговите и социални изследвания и консултации.</p>

            <p>Компанията създаде и оперира казва.бг (kazva.bg), с цел всеки желаещ да изрази бързо и удобно своето мнение по дадена тема и да види как то се сравнява с мнението на останалите потребители.</p>

            <p>Когато темата касае продукти, услуги или инициативи на трета страна (клиент на ЦНТС), kazva.bg предоставя възможност анонимизираното мнение на всеки потребител да бъде чуто директно от служители на високо ниво в съответната организация.</p>

            <p>Може да ни изпратите бизнес запитване или да научите повече за нас и дейността ни на cnts.bg или на имейл: privacy@cnts.bg.</p>

            <hr />

            <h2>II. ПРИЛОЖИМО ЗАКОНОДАТЕЛСТВО</h2>

            <p>Настоящата Политика е изготвена в съответствие с изискванията на:</p>
            <ul>
                <li>Общия регламент за защита на личните данни (Регламент (ЕС) 2016/679, „GDPR");</li>
                <li>Закона за защита на личните данни (ЗЗЛД) и действащото законодателство на Република България.</li>
            </ul>

            <hr />

            <h2>III. РОЛИ: АДМИНИСТРАТОР И/ИЛИ ОБРАБОТВАЩ</h2>

            <p>„ЦНТС" ООД действа като администратор на лични данни по отношение на информацията, която потребителите на kazva.bg предоставят при регистрация и при взаимодействие с платформата. Клиентите (трети страни), които получават достъп до анонимизираната обратна връзка, могат да действат като съвместни администратори или обработващи данните единствено при изрично съгласие на потребителя (когато потребителят желае да сподели свои данни за връзка).</p>

            <hr />

            <h2>IV. ВИДОВЕ ЛИЧНИ ДАННИ, КОИТО СЪБИРАМЕ</h2>

            <p>„ЦНТС" ООД чрез kazva.bg събира само и единствено данни, които потребителят доброволно предоставя за себе си:</p>
            <ul>
                <li>Данни за контакт: име, адрес, телефон, имейл адрес (служебен/личен), ако потребителят желае да ги предостави;</li>
                <li>Основни демографски данни: година на раждане, пол, населено място;</li>
                <li>Допълнителни данни (ако са предвидени в платформата): друга демографска или профилна информация (напр. образование), отново само при доброволно попълване от потребителя.</li>
            </ul>

            <p><strong>Важно:</strong> Потребителите въвеждат данни само за себе си. Платформата не е предназначена за въвеждане на данни за трети лица.</p>

            <hr />

            <h2>V. ОГРАНИЧЕНИЕ ПО ВЪЗРАСТ</h2>

            <p>Платформата kazva.bg не е насочена към деца под 16-годишна възраст. Ако установим, че неволно сме събрали данни на лице под 16 години, ще изтрием такава информация при узнаване на случая.</p>

            <hr />

            <h2>VI. ЦЕЛИ И ПРАВНИ ОСНОВАНИЯ ЗА ОБРАБОТКА</h2>
            <ol>
                <li>Предоставяне на услугата
                    <ul>
                        <li>С цел да може потребителят да се регистрира и използва функциите на kazva.bg, ние обработваме лични данни въз основа на договорно отношение (чл. 6, пар. 1, б. „б" GDPR).</li>
                    </ul>
                </li>
                <li>Съгласие
                    <ul>
                        <li>Когато потребител изрично желае да предостави личните си данни (напр. име, имейл, телефон) на клиент (трета страна), за да получи отговор или да участва в инициатива, правим това само при ясно съгласие (чл. 6, пар. 1, б. „а" GDPR).</li>
                    </ul>
                </li>
                <li>Законово задължение
                    <ul>
                        <li>При необходимост да спазим законово изискване (например счетоводни, данъчни или други нормативни задължения), обработваме данните на основание чл. 6, пар. 1, б. „в" GDPR.</li>
                    </ul>
                </li>
                <li>Легитимен интерес
                    <ul>
                        <li>В определени случаи може да обработваме данни въз основа на нашия легитимен интерес да подобрим услугите си или да осигурим сигурност на платформата (чл. 6, пар. 1, б. „е" GDPR), освен ако не се налага по-висока степен на защита на Вашите основни права и свободи.</li>
                    </ul>
                </li>
            </ol>

            <hr />

            <h2>VII. КАК СЪБИРАМЕ ДАННИ</h2>
            <ol>
                <li>При регистрация на kazva.bg – потребителят попълва основни данни (имейл, парола и др., по избор).</li>
                <li>При използване на платформата – при участие в проучвания, даване на отговори на въпроси, гласуване, попълване на профил и т.н.</li>
                <li>При контакт с нас – когато изпращате запитвания или искания по имейл до ЦНТС ООД.</li>
            </ol>

            <hr />

            <h2>VIII. ПРЕДОСТАВЯНЕ НА ДАННИ НА ТРЕТИ СТРАНИ</h2>
            <ol>
                <li>Анонимизирана информация за клиенти
                    <ul>
                        <li>По подразбиране, всички данни, които се предоставят на клиенти (трети страни), са анонимизирани и нямат пряка възможност да Ви идентифицират.</li>
                    </ul>
                </li>
                <li>Разкриване само при изрично съгласие
                    <ul>
                        <li>Ако потребител изрично даде съгласие за споделяне на своите данни за контакт (име, имейл, телефон и др.) с конкретен клиент, това разкриване се извършва само за тази конкретна цел. Потребителят е ясно информиран коя е организацията, на която предоставя данните.</li>
                    </ul>
                </li>
            </ol>

            <hr />

            <h2>IX. СРОК НА СЪХРАНЕНИЕ</h2>

            <p>Ние съхраняваме личните Ви данни, докато имате активен профил в kazva.bg или докато е необходимо за изпълнение на горепосочените цели. След изтриване на акаунта, данните Ви се заличават или анонимизират в срок до 180 дни, освен ако не е налице законово задължение да ги пазим по-дълго.</p>

            <hr />

            <h2>X. СИГУРНОСТ НА ДАННИТЕ И УВЕДОМЯВАНЕ ПРИ НАРУШЕНИЕ</h2>
            <ul>
                <li>Сървъри в ЕС: Всички данни се съхраняват на сървъри, намиращи се на територията на ЕС, в криптирана форма и при строго контролиран достъп.</li>
                <li>Няма международен трансфер: Не извършваме трансфер на лични данни извън ЕС/ЕИП.</li>
                <li>Мерки за защита: Използваме протоколи за криптиране (SSL/TLS), контрол на достъпа и регулярни проверки за уязвимости.</li>
                <li>Уведомяване при пробив: При установяване на нарушение на сигурността, което може да застраши правата и свободите на физически лица, ще уведомим компетентния надзорен орган (Комисия за защита на личните данни) в рамките на 72 часа, както и съответните субекти, когато е приложимо.</li>
            </ul>

            <hr />

            <h2>XI. БИСКВИТКИ (COOKIES)</h2>
            <ul>
                <li>„kazvabg_cookie": Платформата kazva.bg използва единствено собствена криптирана бисквитка за:
                    <ul>
                        <li>Разпознаване на потребителя;</li>
                        <li>Осигуряване на основните функционалности (запазване на предпочитания, история на мнения и др.);</li>
                        <li>Предотвратяване на злонамерена активност.</li>
                    </ul>
                </li>
                <li>Не използваме Google Analytics, маркетингови или други външни бисквитки.</li>
                <li>Можете по всяко време да изтриете или блокирате бисквитки през настройките на браузъра си, но това може да ограничи функционалността на платформата.</li>
            </ul>

            <hr />

            <h2>XII. ПРАВА НА СУБЕКТИТЕ НА ДАННИ</h2>

            <p>Съгласно GDPR, имате следните права по отношение на Вашите лични данни:</p>
            <ol>
                <li>Право на достъп: Да получите потвърждение дали обработваме Ваши данни и копие от тях.</li>
                <li>Право на коригиране: Да поискате поправка на неточни данни и/или допълване на непълни данни.</li>
                <li>Право на изтриване („да бъдете забравени"): Да поискате заличаване на Вашите данни, ако няма законова или договорна необходимост да ги пазим.</li>
                <li>Право на ограничаване на обработването: Да поискате временно спиране на обработването в определени случаи.</li>
                <li>Право на възражение: Да възразите срещу обработването, когато то се основава на легитимен интерес.</li>
                <li>Право на преносимост: Да получите данните си в структуриран, машиночетим формат и да поискате прехвърляне към друг администратор при техническа възможност.</li>
                <li>Право на оттегляне на съгласие: Ако обработваме данните Ви въз основа на съгласие, можете да го оттеглите по всяко време.</li>
            </ol>

            <hr />

            <h2>XIII. КАК ДА УПРАЖНИТЕ ПРАВАТА СИ</h2>
            <ul>
                <li>Самостоятелно изтриване: Вие можете сам да изтриете профила и данните си директно през настройките на Вашия акаунт в kazva.bg.</li>
                <li>По имейл: Можете да ни изпратите заявка на privacy@cnts.bg с кратко описание кое право искате да упражните и данни, по които да Ви идентифицираме (напр. имейл, с който сте се регистрирали).</li>
                <li>По поща: България, гр. София, ул. Дунав 44 (до ЦНТС ООД).</li>
            </ul>
            <p>Ние ще потвърдим получаването на искането Ви и ще отговорим в рамките на 1 месец, освен ако не е необходим по-дълъг срок (за което ще Ви уведомим своевременно).</p>

            <hr />

            <h2>XIV. МАРКЕТИНГОВИ СЪОБЩЕНИЯ</h2>

            <p>Не изпращаме маркетингови или промоционални съобщения и не използваме данните Ви за подобни цели. Ще се свържем с Вас само при необходимост от поддръжка, технически актуализации и отговор на Ваши заявки.</p>

            <hr />

            <h2>XV. ВЕРСИЯ НА ДОКУМЕНТА И ПРОМЕНИ</h2>

            <p>Не поддържаме публична история на промените. При всяка съществена промяна на тази Политика ще Ви информираме с известие в платформата kazva.bg. Датата на последната актуализация е посочена в края на документа.</p>

            <hr />

            <h2>XVI. ПРАВО НА ЖАЛБА ДО НАДЗОРЕН ОРГАН</h2>

            <p>Ако смятате, че правата Ви за защита на данните са нарушени, имате право да подадете жалба пред:</p>
            <ul>
                <li>Комисия за защита на личните данни (КЗЛД): www.cpdp.bg</li>
            </ul>

            <p>Ще се радваме най-напред да се свържете с нас на privacy@cnts.bg, за да опитаме да разрешим проблема.</p>

            <hr />

            <h2>XVII. ДАННИ ЗА КОНТАКТ С НАС</h2>
            <ul>
                <li>Адрес: България, гр. София, ул. Дунав 44 (ЦНТС ООД)</li>
                <li>Имейл: privacy@cnts.bg</li>
            </ul>

            <p>Дата на последна актуализация: 26.03.2025</p>

            <hr />

            <p>&copy; 2025 ЦНТС ООД. Всички права запазени.</p>
        </>
    );
}
export default PrivacyPolicyDiv;