import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { StyledButtonV2 } from './ConsentOverlay';
import MatomoTracker from '../util/matomo/MatomoTracker';
import { useParams } from 'react-router-dom';
import { AppContext } from '../App';

const VoteConfirmationContainer = styled.div`

    position: absolute;
    bottom: 0;
    padding: 20px;
    width: 100%;
    height: 45%;
    border-radius: 24px 24px 0px 0px;
    background: #E6E6E6;

    h1 {
        font-size: 1.3rem;
        margin: 12px;
    }
    
    p {
        font-size: 1rem;        
        margin: 12px;
    }
`;

const ButtonContainer = styled.div`
    margin: 0px 16px 16px;
    width: calc(100% - 32px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 104px;
`;

interface VoteConfirmationProps {
    handleConfirmVote: () => void;
    handleTryAgain: () => void;
    //getAnswer: (bool: boolean) => void;
}

interface VoteConfirmationState {
    visible: boolean;
}

const VoteConfirmationDialog: React.FC<VoteConfirmationProps> = (props) => {

    const { t } = useTranslation();

    const [state, setState] = useState<VoteConfirmationState>({
        visible: false,
    });

    const { topic } = useContext(AppContext);
    const { topicHash }: { topicHash: string } = useParams();

    useEffect(() => { setState({ visible: true }); }, []);

    const handleConfirmVote = () => {

        MatomoTracker.trackEvent({
            category: 'vote-confirmation',
            action: 'confirm-vote',
            documentTitle: topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ visible: false });
        setTimeout(() => {
            props.handleConfirmVote();
        }, 600);
    };

    const handleTryAgain = () => {

        MatomoTracker.trackEvent({
            category: 'vote-confirmation',
            action: 'try-again',
            documentTitle: topic?.name,
            href: `https://kazva.bg/${topicHash}`
        });

        setState({ visible: false });
        setTimeout(() => {
            props.handleTryAgain();
        }, 600);
    };

    return (
        <div style={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: 1000,
        }}>
            <VoteConfirmationContainer style={{
                transform: state.visible ? 'translateY(0%)' : 'translateY(100%)',
                transition: 'transform 0.6s ease-in-out'
            }}>
                <h1>
                    {t('first_vote_title')}
                </h1>
                <p>
                    {t('first_vote_text1')}
                </p>
                <p>
                    {t('first_vote_text2')}
                </p>
                <ButtonContainer>
                    <StyledButtonV2 onClick={handleConfirmVote}>
                        ✅ {t('first_vote_confirm')}
                    </StyledButtonV2>
                    <StyledButtonV2 onClick={handleTryAgain} style={{ marginTop: '8px' }}>
                        🔄 {t('first_vote_try_again')}
                    </StyledButtonV2>
                </ButtonContainer>
            </VoteConfirmationContainer>
        </div>
    );
};

export default VoteConfirmationDialog;